import './App.css';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom"
import LandingPage from './pages/LandingPage';
import TeamPage from './pages/TeamPage';
import CareersPage from './pages/CareersPage';
import ErrorPage from './pages/ErrorPage';
import AdminsPage from './pages/AdminsPage';



function App() {
  return (
    <Router>
      <Routes>
        {/* PAGES SHOWN FOR THE USER */}
        <Route path="/" element={<LandingPage/>}/>
        <Route path="/team" element={<TeamPage/>}/>
        <Route path="/getinvolved" element={<CareersPage/>}/>

        {
        /* 
        * THIS PAGE CAN ONLY BE ACCESSED THROUGH 
        * APPENDING '/admin' to the landing page's url
        */
        }
        <Route path="/admin" element={<AdminsPage/>}/>
        <Route path="*" element={<ErrorPage />}/>
      </Routes>

    </Router>
      

  );
}

export default App;
